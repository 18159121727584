
  import { defineComponent, ref } from 'vue'
  import { IonButton, IonCol, IonCard, IonIcon, IonLabel, IonRow } from '@ionic/vue';
  import { arrowForwardOutline, exitOutline, logoFacebook, logoLinkedin, logoTwitter, logoInstagram, logoYoutube, personAddOutline, receiptOutline, rocketOutline, settingsOutline } from 'ionicons/icons';

  
  
  export default defineComponent({
    components: { IonButton, IonCol, IonCard, IonIcon, IonLabel, IonRow },
    methods: {
      accountDropdown(str: string) {
        if(str == "0") {
          window.location.replace('https://dev.opencider.com/')
        } else {
          window.location.replace('https://app.opencider.com/')
        }
      },
      becomeADeveloper() {
        location.href = 'https://dev.opencider.com/auth/sign-up'
      },
      becomeAUser() {
        window.location.replace('https://app.opencider.com/auth/sign-up')
      },
      privacyPage() {
        this.$router.push('/privacy')
      },
      readDocumentation() {
        const routeData = this.$router.resolve('/documentation');
        window.open(routeData.href, '_blank');
      },
      tosPage() {
        this.$router.push('/terms')
      },
      calculateCost(num: number) {
        this.cost = Math.floor(num * 0.01)
      },
    },
    data() {
  
      return {
          arrowForwardOutline,
          exitOutline,
          logoFacebook, 
          logoLinkedin, 
          logoTwitter, 
          logoInstagram, 
          logoYoutube,
          personAddOutline,
          receiptOutline, 
          rocketOutline, 
          settingsOutline,
          welcomePage: "/",
          cost: 0,
          pinFormatter: (value: number) => `${value} calls`,
      }
    }
  })
  