
  import { defineComponent } from 'vue'
  import { IonButton, IonCol, IonContent, IonIcon, IonItemDivider, IonLabel, IonRow, IonPage } from '@ionic/vue';
  import { arrowForwardOutline, exitOutline, logoFacebook, logoLinkedin, logoTwitter, logoInstagram, logoYoutube, personAddOutline } from 'ionicons/icons';
  
  
  
  export default defineComponent({
    components: { IonButton, IonCol, IonContent, IonIcon, IonItemDivider, IonLabel, IonRow, IonPage },
    methods: {
      accountDropdown(ev: any) {
        if(ev.detail.value === "0") {
          window.location.replace('https://dev.opencider.com/')
        } else {
          window.location.replace('https://app.opencider.com/')
        }
      },
      becomeADeveloper() {
        window.open('https://dev.opencider.com/auth/sign-in', '_blank')
      },
      becomeAUser() {
        window.open('https://app.opencider.com/auth/sign-in', '_blank')
      },
      callToAction() {
        document.getElementById("call-to-action")?.scrollIntoView()
      },
      openInstagram() {
        window.open('https://instagram.com/opencider', '_blank')
      },
      openLinkedIn() {
        window.open('https://www.linkedin.com/company/opencider', '_blank')
      },
      openTwitter() {
        window.open('https://twitter.com/openciderhq', '_blank')
      },
      openYouTube() {
        window.open('https://www.youtube.com/@opencider', '_blank')
      },
      privacyPage() {
        this.$router.push('/privacy')
      },
      tosPage() {
        this.$router.push('/terms')
      }
    },
    data() {
        return {
            arrowForwardOutline,
            exitOutline,
            logoFacebook, 
            logoLinkedin, 
            logoTwitter, 
            logoInstagram, 
            logoYoutube,
            personAddOutline,
            welcomePage: "/"
        }
    }
  })
  